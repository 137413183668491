import { BaseWidget } from '~/app/core/apiClient/api';
import {
  isAPIMapWidget,
  isCarouselWidget,
  isFaqApiWidget,
  isGalleryWidget,
  isGridWidget,
  isHtmlWidget,
  isIframeWidget,
  isNewsletterWidget,
  isPanoramaWidget,
  isQuizWidget,
  isRepresentationMapWidget,
  isSafeTravelsWidget,
  isSliderWidget,
  isSmartGuideWidget,
  isTabsWidget as isTabsApiWidget,
  isTradeOffersWidget,
  isVenueFinderWidget,
} from '~/app/core/apiClient';
import {
  createCarousel,
  createContentBlock,
  createFaqWidget,
  createGallery,
  createGridList,
  createIframeBlock,
  createMapWidget,
  createNewsletterForm,
  createPanorama,
  createQuizWidget,
  createRepresentationMap,
  createSafeTravels,
  createSlider,
  createSmartGuideWidget,
  createTabs,
  createTradeOffersList,
  createVenueFinder,
} from '~/utils/views/components';
import { GalleryItemInterface } from '~/components/molecules/galleryItem/GalleryItem';
import { CarouselInterface } from '~/components/organisms/carousel/types';
import { ContentBlockInterface } from '~/components/organisms/contentBlock/types';
import { GalleryInterface } from '~/components/organisms/gallery/types';
import { GridListInterface } from '~/components/organisms/gridList/types';
import { NewsletterFormInterface } from '~/components/templates/common/newsletterForm/types';
import { PanoramaInterface } from '~/components/organisms/panorama/types';
import { RepresentationMapInterface } from '~/components/templates/common/representationMap/types';
import { SearchFormInterface } from '~/components/organisms/searchForm/types';
import {
  SliderInterface,
  SliderItemInterface,
} from '~/components/organisms/slider/types';
import { TabsWidgetInterface } from '~/components/templates/common/tabsWidget/types';
import { MapWidgetProps } from '~/components/templates/mapWidget/types';
import { VenueFinderInterface } from '~/components/templates/common/venueFinder/types';
import isGridList from '~/components/organisms/gridList/isGridList';
import isSlider from '~/components/organisms/slider/isSlider';
import isCarousel from '~/components/organisms/carousel/isCarousel';
import isGallery from '~/components/organisms/gallery/isGallery';
import isContentBlock from '~/components/organisms/contentBlock/isContentBlock';
import isTabsWidget from '~/components/templates/common/tabsWidget/isTabsWidget';
import isTradeOffersList from '~/components/templates/common/tradeOffersList/isTradeOffersList';
import isPanorama from '~/components/organisms/panorama/isPanorama';
import { SmartGuideInterface } from '~/components/organisms/smartGuide/types';
import { FaqInterface } from '~/components/organisms/faq/types';

export enum CztWidgets {
  CAROUSEL = 'VCR.CarouselWidget',
  CAROUSEL_ITEM = 'VCR.CarouselWidgetItem',
  GALLERY = 'VCR.GalleryWidget',
  FAQ = 'VCR.FaqWidget',
  GRID = 'VCR.GridWidget',
  GRID_ITEM = 'VCR.GridWidgetItem',
  HTML = 'VCR.HtmlContentWidget',
  IFRAME = 'VCR.IframeWidget',
  MAP = 'VCR.MapWidget',
  NEWSLETTER = 'VCR.NewsletterFormWidget',
  QUIZ = 'VCR.QuizWidget',
  SAFE_TRAVELS = 'VCR.SafeTravelsWidget',
  SEARCH = 'VCR.SearchPlaceholder',
  SLIDER = 'VCR.SliderWidget',
  SLIDER_ITEM = 'VCR.SliderWidgetItem',
  SMART_GUIDE = 'VCR.SmartGuideWidget',
  PANORAMA = 'VCR.PanoramicPhotoWidget',
  TABS = 'VCR.TabbedContentWidget',
  TRADE_OFFFERS = 'VCR.TradeOffersWidget',
  REPRESENTATION_MAP = 'VCR.ForeignRepsMapWidget',
  VENUE_FINDER = 'VCR.VenueFinderWidget',
}

export type Widget =
  | CarouselInterface
  | ContentBlockInterface
  | FaqInterface
  | GalleryInterface
  | GridListInterface
  | NewsletterFormInterface
  | PanoramaInterface
  | RepresentationMapInterface
  | SearchFormInterface
  | SliderInterface
  | SmartGuideInterface
  | TabsWidgetInterface
  | MapWidgetProps
  | VenueFinderInterface;

/**
 * Typeguard to filter out null widgets that could not be matched
 * @param item
 */
function notEmptyWidget<TItem>(item: TItem | null): item is TItem {
  return item !== null;
}

export function createWidgets(widgets: BaseWidget[]): Widget[] {
  const mappedWidgets = widgets.map((widget) => {
    switch (true) {
      case isHtmlWidget(widget):
        return createContentBlock(widget);
      case isCarouselWidget(widget):
        return createCarousel(widget);
      case isGridWidget(widget):
        return createGridList(widget);
      case isIframeWidget(widget):
        return createIframeBlock(widget);
      case isRepresentationMapWidget(widget):
        return createRepresentationMap(widget);
      case isSliderWidget(widget):
        return createSlider(widget);
      case isSmartGuideWidget(widget):
        return createSmartGuideWidget(widget);
      case isTabsApiWidget(widget):
        return createTabs(widget);
      case isGalleryWidget(widget):
        return createGallery(widget);
      case isFaqApiWidget(widget):
        return createFaqWidget(widget);
      case isTradeOffersWidget(widget):
        return createTradeOffersList(widget);
      case isNewsletterWidget(widget):
        return createNewsletterForm(widget);
      case isSafeTravelsWidget(widget):
        return createSafeTravels(widget);
      case isPanoramaWidget(widget):
        return createPanorama(widget);
      case isQuizWidget(widget):
        return createQuizWidget(widget);
      case isAPIMapWidget(widget):
        return createMapWidget(widget);
      case isVenueFinderWidget(widget):
        return createVenueFinder(widget);
    }

    return null;
  });

  return mappedWidgets.filter(notEmptyWidget);
}

export function getImageFromWidget(widget: Widget): string | undefined {
  if (isGridList(widget)) {
    return widget.image?.src;
  } else if (isSlider(widget)) {
    return widget.items.length > 0
      ? widget.items[0].backgroundImage.src
      : undefined;
  } else if (isCarousel(widget)) {
    return widget.items.length > 0 ? widget.items[0].image.src : undefined;
  } else if (isGallery(widget)) {
    return widget.items.length > 0 ? widget.items[0].image.src : undefined;
  } else if (isContentBlock(widget)) {
    return widget.image?.src;
  } else if (isTabsWidget(widget)) {
    return widget.image?.src;
  } else if (isTradeOffersList(widget)) {
    return widget.items.length > 0 ? widget.items[0].image.src : undefined;
  } else if (isPanorama(widget)) {
    return widget.image;
  }
  return undefined;
}

function createGalleryItemFromSlide(
  item: SliderItemInterface
): GalleryItemInterface {
  return {
    image: item.backgroundImage,
    imageFilter: item.imageFilter,
    subtitle: item.superTitle || '',
    title: item.title,
    hideTitle: item.hideTitle,
    video: item.video,
    showVideoControls: item.showVideoControls,
  };
}

export function createGalleryFromSlider(
  slider: SliderInterface
): GalleryInterface {
  return {
    className: CztWidgets.GALLERY,
    items: slider.items.map(createGalleryItemFromSlide).filter((item) => {
      return !!(item.image.src || item.video?.src);
    }),
    ratio: slider.ratio,
  };
}
